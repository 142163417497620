<template>
  <div>
    <ClientOnly>
      <!-- Share -->
      <div
        v-if="isSupported"
        class="!m-0 btn btn-secondary"
        @click="startShare"
      >
        {{ $t("general.share") }}
      </div>
      <!-- Apply -->
      <template v-if="vacancyStatus == 'open'">
        <div
          v-if="!vacancyExternalApplyUrl"
          class="!m-0 btn sticky"
          @click.prevent="openApplyModal"
        >
          {{ $t("general.apply") }}
        </div>
        <NuxtLink
          v-else
          :to="vacancyExternalApplyUrl"
          class="!m-0 btn"
          target="_blank"
          rel="noopener"
          >{{ $t("general.apply") }}</NuxtLink
        >
      </template>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
const { open: openApplyModal } = useWerkgelukModal("apply");
const { share, isSupported } = useShare();
const route = useRoute();

const props = defineProps<{
  vacancyTitle: string | undefined;
  vacancyStatus: string | undefined;
  vacancyExternalApplyUrl: string | undefined;
}>();

const startShare = () => {
  share({
    title: props.vacancyTitle,
    text: props.vacancyTitle,
    url: window.location.href,
  });
};

onMounted(() => {
  if (route.hash === "#solliciteer") {
    openApplyModal();
  }
});
</script>
